@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
body {
  margin: 0;
  padding: 0;
  font-family: "Figtree", sans-serif;
  font-weight: 400;
  background: #202125 url(./assets/images/subscribe-bg.png) no-repeat scroll
    center center;
  background-size: cover;
  color: #ffffff;
  overflow-x: hidden;
}
a {
  color: #fcd535;
  text-decoration: none;
}
a:hover {
  color: #ff437f;
  text-decoration: underline;
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
  background: #000b0d;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #2b3139;
}

.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #061c1f;
  margin-top: -100px;
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  z-index: 100;
}
.navbar-sticky--on {
  margin-top: 0;
}
.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.navbar {
  padding-top: 15px;
}

.navbar_right {
  display: flex;
  align-items: center;
}
.navbar_right a {
  color: #fff !important;
  font-size: 18px;
  text-decoration: none !important;
}
.navbar_right .address_btn {
  padding: 10px 15px;
}
.dropdown-menu {
  background: #252420;
}
.dropdown-item:hover {
  background: #00000015 !important;
}
/* Connect Wallet */
.primary_modal .modal-content {
  background: radial-gradient(
    197.76% 137.72% at 43.91% -4.35%,
    #2a3038 0%,
    #191a1f 65.25%,
    transparent 100%
  );
}

.primary_modal .modal-header {
  border-bottom: 1px solid #414955;
  padding: 20px 24px 15px;
}

.connect_wallet_button {
  border: 2px solid #414955;
  background-color: transparent;
  color: #000;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
}
.btn-close {
  filter: invert(1);
}
.connect_wallet_button:hover {
  border-color: #fcd535;
}

.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #fff;
}

.connect_wallet_button img {
  margin-right: 15px;
}

.connect_wallet_button span {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}

.btn-close {
  width: 32px;
  height: 32px;
  color: #fff;
  border: 0;
  /* background: transparent url("../src/assets/images/close_icon.png") no-repeat; */
  opacity: 0.8;
  outline: none;
  box-shadow: none !important;
}

.btn-close:hover {
  opacity: 1;
}
.navbar-brand,
.navbar-toggler {
  outline: 0;
  box-shadow: none !important;
}
.offcanvas {
  background: #000506;
}

.primary_modal a {
  text-decoration: none;
}

.primary_btn {
  border-radius: 7px;
  border: 1px solid #fcd535;
  background: linear-gradient(90deg, #191a1f, #fcd535);
  padding: 8px 15px;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

h6 {
  font-family: "Poppins", sans-serif;
}

.dashboard {
  min-height: 100vh;
  padding-top: 50px;
}
.dashboard h6 {
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 0;
}
.dashboard h5 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0;
}
.dashboard p {
  font-size: 18px;
  margin-bottom: 0;
}

.dashboard .box {
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  width: 100%;
  border-radius: 20px;
  background: radial-gradient(
    57.97% 135.09% at 47.31% -35.09%,
    #4f5762 0%,
    #23272e 100%
  );
}
.dashboard .box .icon {
  background: linear-gradient(to bottom, #fcd535, transparent);
  width: 65px;
  height: 65px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard .box .link {
  box-shadow: none;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  border: 0px solid #121212;
  border-radius: 10px;
  background: #1f2328;
}

.dashboard .box .link .half {
  width: 83%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}

.dashboard .box .link .copy {
  cursor: pointer;
}

.dashboard .box ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard .box ul a {
  background: #6d757e;
  color: #2b3139 !important;
  text-decoration: none !important;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 8px;
  transition: 0.4s;
}

.dashboard .box ul a:hover {
  background: #fff;
}

.dashboard .top .box {
  flex-direction: column;
  text-align: center;
}
.dashboard .top .box p {
  margin-bottom: 10px;
}
.dashboard .bdr {
  border-radius: 20px;
  border: 1px solid #414955;
  padding: 20px;
}
.dashboard .box .flx {
  display: flex;
  align-items: center;
}
.dashboard .amount {
  padding: 30px 70px;
  margin-bottom: 0;
}
.dashboard .t_flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
}
.dashboard .t_flx .input-group {
  width: 58%;
}
.dashboard .t_flx input {
  border-color: #414955;
  background: #2c3139;
  padding: 10px;
  border-radius: 10px 0 0 10px;
  box-shadow: none !important;
  padding-left: 20px;
  color: #fff;
  caret-color: rgb(189, 189, 189);
}
.dashboard .t_flx input::placeholder {
  color: #828491;
}
.dashboard .t_flx .input-group-text {
  border-color: #414955;
  background: #2b3139;
  color: #828491;
  border-radius: 0px 10px 10px 0px;
  padding-right: 20px;
}

.dashboard .table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: transparent;
}

.dashboard .table > :not(caption) > * > * {
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  border-bottom: 1px solid #414955;
  white-space: nowrap;
  padding: 12px;
}

.dashboard .table tr:nth-last-child(1) td {
  border: 0;
}

.dashboard .table th {
  color: #fff;
  background: #2b3139;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  border: 0;
}
.dashboard .table th:nth-child(1) {
  border-radius: 10px 0 0 10px;
  padding-left: 30px;
}
.dashboard .table th:nth-last-child(1) {
  border-radius: 0px 10px 10px 0px;
}
.dashboard .table td:nth-child(1) {
  padding-left: 30px;
}
.dashboard .nav {
  border-bottom: 0px solid #121212;
  margin-bottom: 18px;
}
.dashboard .nav-link {
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  padding-left: 0;
  border: 0 !important;
  border-radius: 7px;
  background: #2b3139;
  padding: 8px 25px;
  margin-bottom: 10px;
  margin-right: 20px;
}
.dashboard .nav-link.active,
.dashboard .nav-link:hover {
  background: #fcd535;
  color: #0a2328;
}

.plan_pool {
  margin: 0 auto;
}
.plan_pool img {
  z-index: 1;
}
.plan_pool .plan_pool_third_row img {
  height: 38px;
}
.plan_pool_head {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 56px;
  position: relative;
  margin: 10px auto 40px;
}

.plan_pool_head::after {
  content: "";
  background-color: #6d757e;
  width: 1px;
  height: 67%;
  position: absolute;
  bottom: -40px;
}
.plan_pool_head_child {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  position: relative;
  margin: 29px 5px 0;
}
.plan_pool_first_row .plan_pool_head_child {
  width: 100px;
  height: 100px;
  font-size: 36px;
}
.plan_pool_first_row {
  display: flex;
  justify-content: space-around;
  position: relative;
}
.plan_pool_first_row::after {
  content: "";
  background-color: #6d757e;
  width: 50%;
  height: 1px;
  position: absolute;
}
.plan_pool_head_child::before {
  content: "";
  background: #6d757e;
  width: 1px;
  height: 75%;
  position: absolute;
  top: -29px;
}
.plan_pool_second_row .plan_pool_head_child {
  width: 85px;
  height: 85px;
  font-size: 30px;
  border-radius: 12px;
}
.plan_pool_second_row > div {
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-top: 30px;
  width: 50%;
}
.plan_pool_second_row,
.plan_pool_third_row,
.plan_pool_fourth_row,
.plan_pool_fifth_row {
  display: flex;
  justify-content: space-around;
}
.plan_pool_first_row .plan_pool_head_child::after,
.plan_pool_second_row .plan_pool_head_child::after,
.plan_pool_fourth_row .plan_pool_head_child::after {
  content: "";
  background-color: #6d757e;
  width: 1px;
  height: 60%;
  position: absolute;
  bottom: -31px;
}

.plan_pool_third_row > div,
.plan_pool_fourth_row > div,
.plan_pool_fifth_row > div {
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-top: 30px;
  width: 50%;
}
.plan_pool_second_row > div::before,
.plan_pool_third_row > div::before,
.plan_pool_third_row > div::before,
.plan_pool_fourth_row > div::before,
.plan_pool_fifth_row > div::before {
  content: "";
  background-color: #6d757e;
  width: 50%;
  height: 1px;
  position: absolute;
}
.plan_pool_third_row .plan_pool_head_child {
  width: 65px;
  height: 65px;
  font-size: 24px;
  border-radius: 10px;
}
.plan_pool_fourth_row .plan_pool_head_child {
  width: 45px;
  height: 45px;
  font-size: 20px;
  border-radius: 8px;
}
.plan_pool_fifth_row .plan_pool_head_child {
  width: 24px;
  height: 24px;
  border-radius: 5px;
  font-size: 12px;
}
.position-relative {
  position: relative;
}
.flexcount {
  position: absolute;
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

.main_footer {
  background: #212229;
  color: #e7f3f9;
}

.footer_top {
  padding: 50px 0 30px;
  font-size: 18px;
}
.footer_top .mrgin {
  margin-top: 30px !important;
}

.footer_bottom {
  padding-bottom: 15px;
  border-top: 1px solid #164e59;
  padding-top: 5px;
}
.footer_bottom div {
  display: flex;
  justify-content: space-between;
}
.footer_top h5 {
  font-size: 18px;
  margin-bottom: 25px !important;
}

.footer_top li {
  margin-bottom: 10px;
}

.footer_top li a {
  font-size: 18px;
  font-weight: 400;
  color: #e7f3f9;
  transition: 0.2s all;
}

.footer_top li a:hover {
  color: #e7f3f9;
  text-decoration: none;
}

.footer_content {
  margin-top: 15px;
}

.footer_content p {
  font-size: 18px;
  margin-bottom: 0;
  color: #e7f3f9;
  font-weight: 400;
  max-width: 80%;
}
.social_links {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px !important;
  padding-left: 0;
}
.social_links li {
  margin: 0 8px;
  display: flex;
  align-items: center;
}

.social_links li a {
  font-size: 18px;
  color: #576474 !important;
}
.social_links li a img {
  height: 18px;
}
/* .social_links li a:hover img{
  filter: invert(1) brightness(10.5);
} */
.social_links li a span {
  font-size: 16px;
}

.footer_bottom p {
  text-align: center;
  font-size: 15px;
  font-weight: 300;
  margin: 10px 0 0;
  color: var(--color-text-secondary) !important;
}
.footer_bottom a {
  text-decoration: none;
  color: #fff;
}

.signup {
  background: #0a2328;
}

.signup .primary_btn {
  font-size: 16px;
  padding: 9px 20px;
  box-shadow: 0 0 3px 0 var(--btnshadow);
  margin: 8px;
  margin-left: 0 !important;
  border-radius: 7px !important;
  border: 1px solid #006a99 !important;
  background: linear-gradient(90deg, #191a1f, #fcd535);
}

.signup input {
  background: #0a2328 !important;
  color: #fff !important;
  border-right: 0;
  box-shadow: none !important;
  border: 0;
  border-radius: 10px;
  padding-left: 25px;
}
.signup input:focus {
  color: #1a5b66;
}
.signup input::placeholder {
  font-size: 14px;
  color: #1a5b66;
}
.signup input:-webkit-autofill {
  background: #0a2328 !important;
  caret-color: #fff;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #0a2328 inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
.signup .inputgrp {
  border: 1px solid #113d45;
  border-radius: 8px;
  overflow: hidden;
}

.success .modal-header {
  border: 0;
}
.success .box {
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  border: 1px solid #3a9295;
  padding: 20px 40px;
  border-radius: 10px;
  margin-top: -20px;
}
.success .box i {
  color: #fcd535;
  font-size: 30px;
  display: block;
  margin-bottom: 20px;
}
.flex-input-group {
  display: flex;
}
.flex-input-group input {
  margin-right: 7px;
}
.radio-group {
  margin-right: 20px;
}
.label {
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 14px 16px;
  margin: 5px 0;
  cursor: pointer;
  transition: 0.3s;
}

.label.active {
  background: hsla(0, 0%, 90%, 0.34);
}

.radio-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}

.radio-design {
  width: 22px;
  height: 22px;
  border-radius: 100px;
  background: linear-gradient(90deg, #191a1f, #fcd535);
  position: relative;
  border: 1px solid #fcd535;
}

.radio-design::before {
  content: "";
  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: hsl(0, 0%, 90%);
  transform: scale(1.1);
  transition: 0.3s;
}

.radio-input:checked + .radio-design::before {
  transform: scale(0);
}

.label-text {
  color: #ffffff;
  margin-left: 14px;
  transition: 0.3s;
}

/**** tick symbol withdraw******/
.svg {
  width: 80px;
  display: block;
  margin: 23px auto 0;
  filter: hue-rotate(-40deg);
}

.svgtick {
  width: 20px;
  display: block;
  margin: 2px auto 0;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  &.circle {
    -webkit-animation: dash 0.9s ease-in-out;
    animation: dash 0.9s ease-in-out;
  }
  &.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
    animation: dash 0.9s 0.35s ease-in-out forwards;
  }
  &.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
    animation: dash-check 0.9s 0.35s ease-in-out forwards;
  }
}

p {
  &.success {
    color: #73af55;
  }
  &.error {
    color: #d06079;
  }
}
.dashboardloader {
  display: flex;
  align-items: center;
  justify-content: center;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
.searchIcon {
  cursor: pointer;
}
/**** tick symbol withdraw******/

.tabs .primary_btn {
  opacity: 0.6;
  background: transparent;
}
.tabs .primary_btn.active {
  opacity: 1;
}
.mobile {
  width: 100%;
}
.mobile .flag-dropdown,
.react-tel-input .selected-flag {
  z-index: 1000;
  border: 0 !important;
}
.mobile .react-tel-input {
  width: 100% !important;
  margin: 0 !important;
}
.mobile .react-tel-input input {
  padding-left: 45px;
  height: 50px !important;
}
.search .search-box {
  height: 36px !important;
}
.mobile .flag-dropdown,
.react-tel-input .selected-flag {
  background: #414955 !important;
  border: 0 !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.react-tel-input .country-list .search-box {
  border-color: #414955 !important;
}
.mobile ul {
  display: block !important;

  background: #2c3139 !important;
}
.react-tel-input .country-list .search,
.react-tel-input .country-list .country.highlight,
.react-tel-input .country-list .country.highlight:hover,
.react-tel-input .country-list .country:hover,
.react-tel-input .flag-dropdown.open .selected-flag {
  background: #2c3139 !important;
}

.btnalign {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.checkbox_tick .form-check-input {
  border-radius: 5px !important;
  padding-left: 10px !important;
  margin: 0 10px 20px 0;
  background: #262629 !important;
}
.checkbox_tick .form-check-input:checked[type="checkbox"] {
  background: #06202b url("../src/assets/images/download.svg") no-repeat !important;
}
.form-check.checkbox_tick {
  padding: 0;
}

.input_box input {
  border-color: #414955;
  background: #2c3139;
  box-shadow: none !important;
  color: #fff;
}
.input_box input:focus {
  border-color: #414955;
  background: #2c3139;
  color: #fff !important;
  box-shadow: none !important;
}
.input_box input::placeholder {
  color: #828491;
}

.absolute-close {
  position: absolute;
  top: 15px;
  right: 15px;
}
.head_tab {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 15px;
  padding-bottom: 10px;
  overflow-x: auto;
}
.head_tab .primary_btn {
  min-width: 245px;
  text-align: center;
  text-decoration: none !important;
  font-size: 22px;
  color: #fff;
  padding: 8px 5px;
}
.head_tab .primary_btn:not(.active) {
  background: #2b3139;
  color: #fff;
  border: 1px solid transparent;
}
.head_tab .primary_btn:not(.active):hover {
  border: 1px solid #fcd535;
  background: linear-gradient(90deg, #191a1f, #fcd535);
}

.text-wrap {
  word-break: break-word;
}

.scroll {
  height: 355px;
  overflow-y: scroll;
}
.react-switch-bg {
  background: rgb(167 141 44) !important;
}
button.primary_btn.disabledbtn {
  opacity: 0.35;
}
.bg-warning {
  background-color: #b39827 !important;
}
.mon {
  font-size: 15px;
  margin-top: 1px;
}

.usr_tree .treeview {
  color: #000;
  overflow: visible !important;
  position: relative;
  padding-top: 30px !important;
}
.usr_tree .treeview i {
  font-size: 20px;
}
.usr_tree .treeview .id {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.usr_tree .treeview .blur {
  opacity: 0.4;
}
.usr_tree .treeview div:not(.treeview) {
  padding-left: 30px !important;
  border-color: #6d757e !important;
  border-left-style: solid !important;
}
.usr_tree .treeview .treeview::before {
  content: "";
  position: absolute;
  width: 30px;
  left: -30px;
  height: 1px;
  background: #6d757e;
  top: 51px;
}
/* hide last user line */
.usr_tree .tree .treeview::before {
  border: 0;
}
.usr_tree .treeview .treeview:nth-last-child(1)::before {
  content: "";
  position: absolute;
  width: 30px;
  left: -31px;
  height: 100%;
  background: #2b2c2f;
  border-top: 1px solid #6d757e;
}
/* .usr_tree .treeview div:not(.treeview)::before{
  content: "";
  background: #aa9292;
  height: calc(50% - 20px);
  width: 15px;
  display: block;
  left: -9px;
  position: absolute;
  bottom: 0;
} */
/* .usr_tree .treeview div:not(.treeview)::before {
  content: "";
  background: #9f7f7f;
  height: calc(50% - 20px);
  width: 1px;
  display: block;
  left: -1px;
  position: absolute;
  top: 0;
} */
.bgcolor {
  background: #2b2c2f;
}
.grid_box {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  margin-bottom: 15px;
}
.dashboard .vertical_tab .nav-link {
  margin-right: 0;
}
.joinnow_box {
  padding: 25px;
  width: 100%;
  border-radius: 20px;
  background: radial-gradient(
    57.97% 135.09% at 47.31% -35.09%,
    #4f5762 0%,
    #23272e 100%
  );
  text-align: center;
}
.joinnow_box > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.grid_box_large {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.dashboard .grid_box_large .box {
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.dashboard .bdr .box {
  margin-bottom: 0;
}
.joinnow_box .primary_btn {
  padding: 12px 50px;
  font-weight: 700;
  margin-top: 30px;
}
.grid_box .box {
  margin-bottom: 0;
}

.inbox.affiliate{
  max-width: 100%;
}
@media (max-width: 1199px) {
  .dashboard h6 {
    font-size: 24px;
  }
  .dashboard h5 {
    font-size: 30px;
  }
  .dashboard .amount {
    padding: 30px 30px;
    flex-direction: column;
  }
  .dashboard .amount .flx:nth-child(2) {
    margin-top: 10px;
  }
  .joinnow_box > div {
    flex-wrap: wrap;
    gap: 20px 50px;
    justify-content: center;
  }
  .grid_box {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (max-width: 991px) {
  .dashboard .bdr {
    margin-top: 24px;
  }
  .dashboard .box ul {
    justify-content: unset;
  }
  .footer_top h5 {
    margin-bottom: 10px !important;
    margin-top: 25px;
  }
  .navbar_right {
    flex-direction: column;
  }
  .navbar_right a {
    margin: 5px 0 !important;
  }
  .navbar_right .primary_btn,
  .navbar_right .dropdown {
    display: block;
    margin: 5px 0 !important ;
  }
  
  .vertical_tab {
    flex-direction: row !important;
    gap: 10px;
    margin-bottom: 0 !important;
  }
  .dashboard .bdr .box {
    margin-bottom: 10px;
  }
  .grid_box .box {
    margin-bottom: 0 !important;
  }
  .grid_box {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (max-width: 767px) {
  .head_tab {
    gap: 10px;
  }
  .head_tab .primary_btn {
    font-size: 22px;
  }
  .dashboard .t_flx {
    flex-direction: column;
  }
  .dashboard .t_flx .input-group {
    width: 98%;
    margin-top: 20px;
  }
  .dashboard .box .flx {
    flex-direction: column;
  }
  .dashboard .box .flx p {
    margin-right: 0 !important;
  }
  .dashboard .scroll {
    overflow-x: scroll;
  }
  .plan_pool {
    width: 600px;
  }
  .footer_bottom div {
    flex-direction: column;
  }
  .flexcount {
    position: static;
    width: 100%;
  }
  .grid_box_large {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (max-width: 575px) {
  .flexcount {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
input.primary-input {
  background: #000000;
  width: 90%;
  margin: 28px auto;
  padding: 10px;
  border-radius: 9px;
  border: 2px solid #919235;
  color: #ffffff;
}

.loadBefore::before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgb(10 10 10 / 75%);
  z-index: 1500;
  overflow: hidden;
}
/* Dopamine CSS */
.nft-image-box{
  border-radius: 20px;
  background: radial-gradient(134.46% 114.93% at 50% 0%, rgba(252, 213, 53, 0.44) 6.24%, rgba(24, 26, 32, 0.26) 43.53%, rgba(6, 7, 8, 0.70) 100%);
  padding: 24px;
}
.nft-image-box .img-border{
  width: 400px;
  height: 375px;
}
.nft-image-box img{
  border-radius: 10px;
  max-height: 100%;
  min-width: 100%;
}
.nft-big-image{
  border-radius: 20px;
  background: radial-gradient(134.46% 114.93% at 50% 0%, rgba(252, 213, 53, 0.44) 6.24%, rgba(24, 26, 32, 0.26) 43.53%, rgba(6, 7, 8, 0.70) 100%);
  padding: 30px;
}
.nft-big-image img{
  border-radius: 10px;
}
.img-border{
  border-radius: 10px;
  background: rgb(252,213,53);
  background: linear-gradient(184deg, rgba(252,213,53,1) 0%, rgba(255,255,255,1) 50%, rgba(252,213,53,1) 100%); 
  padding: 1px;
}
.nft-detail-box{
  border-radius: 20px;
  border: 0px solid #26272C;
  background: #242529;
  padding: 20px;
  height: 100%;
}
.nft-detail-box h3{
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal; 
  color: #FFF; 
  margin-bottom: 15px;
}
.nft-detail-box ul{
  padding: 0;
  margin: 15px 0 25px;
}
.nft-detail-box ul li{
  display: flex;
  padding: 20px;
  justify-content:flex-start;
  align-items: center; 
  border-radius: 10px;
  background: #26272C; 
  margin: 10px 0;
}
.nft-detail-box ul li label{
  margin: 0;
  min-width: 180px;
  color: #80868D; 
  font-size: 20px; 
  display: inline-flex;
}
.nft-detail-box ul li span{
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal; 
}
.nft-detail-box .form-control{
  border-radius: 10px;
  border: 1px solid #424A52;
  background: #26272C; 
  padding: 15px 20px; 
  margin-bottom: 20px;
  color: #ffffff;
  box-shadow: none !important;
}
.back-link{
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.back-link a{
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal; 
  text-decoration: none;
}
.img-bottom{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.img-bottom p{
  margin: 0;
}
.img-bottom a{
  color: #ffffff;
  text-decoration: none;
  font-size: 24px; 
  font-weight: 600;
}
.nft-inverstment-details{
  border-radius: 20px;
  background: radial-gradient(134.46% 114.93% at 50% 0%, #2B3139 6.24%, #181A20 43.53%, #2B3139 100%);
  display: flex;
  padding: 40px;
  justify-content: space-between;
  align-items: center; 
  margin-bottom: 30px;
}
.nid-card{
  display: inline-flex;
  align-items: center;
}
.nid-card img{
  margin-right: 15px;
}
@media (max-width: 991px) {
  .nft-inverstment-details{
    flex-direction: column;
  }
  .nid-card{
    margin: 0px 0 30px;
  }
  .img-thumb{
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
}
@media (max-width: 575px) {
  .nft-image-box .img-border{
    max-width: 100%;
    height: auto;
  }
  .nft-detail-box{
    margin-top: 30px;
  }
}

button.dsable:disabled  {
  opacity: .4;
}